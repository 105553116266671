// Reusuable classes

.container {
  max-width: 1640px;
  margin: 0 auto;
  padding: 0 15px;
  @media (max-width: 1699px) {
    max-width: 1310px;
  }
  @media (max-width: 1359px) {
    max-width: 1170px;
  }
  @media (max-width: 1199px) {
    max-width: 970px;
  }
  @media (max-width: 991px) {
    max-width: 740px;
  }
  @media (max-width: 767px) {
    max-width: 530px;
  }
  @media (max-width: 575px) {
    max-width: 100%;
    width: 100%;
  }
}

// BURGER
.header__burger {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 30px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  z-index: 100;
}
.header__burger-item {
  width: 60px;
  height: 2px;
  background-color: $mainColor;
  transition: all 0.5s ease-in-out;
}
.header__burger-item::before,
.header__burger-item::after {
  content: "";
  position: absolute;
  width: 47px;
  height: 2px;
  background: $mainColor;
  transition: all 0.5s ease-in-out;
}
.header__burger-item::before {
  transform: translate(13px, -8px);
}
.header__burger-item::after {
  transform: translate(13px, 8px);
}

.header__burger.open .header__burger-item {
  transform: translateX(-50px);
  background: transparent;
  box-shadow: none;
}
.header__burger.open .header__burger-item::before {
  transform: rotate(45deg) translate(35px, -35px);
}
.header__burger.open .header__burger-item::after {
  transform: rotate(-45deg) translate(35px, 35px);
}


@media (max-width: 576px) {
  .header__burger {
    width: 50px;
  }
  .header__burger-item {
    width: 50px;
  }
  .header__burger-item::before,
  .header__burger-item::after {
    width: 40px;
  }
  .header__burger-item::before {
    transform: translate(9px, -8px);
  }
  .header__burger-item::after {
    transform: translate(9px, 8px);
  }
}

// ANIMATION
[data-animate] {
  opacity: 0;
  transform: translateY(20px);
  transition: all .8s ease;
  &.animate {
      opacity: 1;
      transform: translateY(0);
  }
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden; }

a {
  text-decoration: none; }

ul, li {
  margin: 0;
  padding: 0;
  list-style: none; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.container {
  max-width: 1640px;
  margin: 0 auto;
  padding: 0 15px; }
  @media (max-width: 1699px) {
    .container {
      max-width: 1310px; } }
  @media (max-width: 1359px) {
    .container {
      max-width: 1170px; } }
  @media (max-width: 1199px) {
    .container {
      max-width: 970px; } }
  @media (max-width: 991px) {
    .container {
      max-width: 740px; } }
  @media (max-width: 767px) {
    .container {
      max-width: 530px; } }
  @media (max-width: 575px) {
    .container {
      max-width: 100%;
      width: 100%; } }

.header__burger {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 30px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  z-index: 100; }

.header__burger-item {
  width: 60px;
  height: 2px;
  background-color: #EEC57C;
  transition: all 0.5s ease-in-out; }

.header__burger-item::before,
.header__burger-item::after {
  content: "";
  position: absolute;
  width: 47px;
  height: 2px;
  background: #EEC57C;
  transition: all 0.5s ease-in-out; }

.header__burger-item::before {
  transform: translate(13px, -8px); }

.header__burger-item::after {
  transform: translate(13px, 8px); }

.header__burger.open .header__burger-item {
  transform: translateX(-50px);
  background: transparent;
  box-shadow: none; }

.header__burger.open .header__burger-item::before {
  transform: rotate(45deg) translate(35px, -35px); }

.header__burger.open .header__burger-item::after {
  transform: rotate(-45deg) translate(35px, 35px); }

@media (max-width: 576px) {
  .header__burger {
    width: 50px; }
  .header__burger-item {
    width: 50px; }
  .header__burger-item::before,
  .header__burger-item::after {
    width: 40px; }
  .header__burger-item::before {
    transform: translate(9px, -8px); }
  .header__burger-item::after {
    transform: translate(9px, 8px); } }

[data-animate] {
  opacity: 0;
  transform: translateY(20px);
  transition: all .8s ease; }
  [data-animate].animate {
    opacity: 1;
    transform: translateY(0); }

.header__inner {
  background-color: #000; }

.header__container {
  padding-top: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.header__logo {
  display: inline-block;
  max-width: 367px; }
  .header__logo > img {
    width: 100%;
    height: auto; }

.header__list {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.header__item:not(:last-child) {
  margin-right: 89px; }

.header__item > a {
  font-size: 14px;
  line-height: 1.5;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase; }
  .header__item > a:hover {
    color: #EEC57C; }

.header__item.btn {
  border: 1px solid #EEC57C;
  font-size: 14px;
  line-height: 1.5;
  color: #000;
  border-radius: 150px;
  padding: 26px 50px;
  background-color: #EEC57C;
  padding: 0;
  transition: all .2s ease; }
  .header__item.btn > a {
    color: #000;
    display: inline-block;
    width: 100%;
    height: 100%;
    padding: 26px 50px; }
  .header__item.btn:hover {
    opacity: .8; }

.header__burger {
  display: none; }

@media (max-width: 1699px) {
  .header__logo {
    max-width: 300px; }
  .header__item:not(:last-child) {
    margin-right: 50px; } }

@media (max-width: 1359px) {
  .header__logo {
    max-width: 270px; }
  .header__item.btn > a {
    padding: 15px 40px; } }

@media (max-width: 1199px) {
  .header__burger {
    display: flex; }
  .header__nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #000;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    transition: all .5s ease; }
    .header__nav.active {
      opacity: 1;
      visibility: visible; }
  .header__list {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%; }
  .header__item {
    margin-bottom: 20px; }
    .header__item:not(:last-child) {
      margin-right: 0; }
    .header__item > a {
      font-size: 20px; }
    .header__item.btn {
      border: none;
      color: #fff;
      border-radius: 0;
      background-color: transparent; }
      .header__item.btn > a {
        padding: 0;
        color: #fff; }
        .header__item.btn > a:hover {
          color: #EEC57C; } }

@media (max-width: 575px) {
  .header__container {
    padding-top: 30px; }
  .header__logo {
    max-width: 180px; } }

.hero {
  background-color: #000;
  position: relative; }
  .hero::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 159px;
    background-color: #fff; }
  .hero__inner {
    padding-top: 100px;
    margin-top: -1px;
    position: relative;
    z-index: 2; }
  .hero__container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
  .hero__left {
    width: 100%;
    max-width: 675px;
    margin-top: 100px;
    overflow: hidden; }
  .hero__nav {
    margin-top: 49px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center; }
    .hero__nav .swiper-button-prev,
    .hero__nav .swiper-button-next {
      position: static;
      border: 2px solid #EEC57C;
      padding: 30px;
      border-radius: 50%; }
      .hero__nav .swiper-button-prev::after,
      .hero__nav .swiper-button-next::after {
        display: none; }
    .hero__nav .swiper-button-next {
      margin-left: 45px; }
      .hero__nav .swiper-button-next img {
        transform: rotate(180deg); }
  .hero__right {
    position: relative;
    width: 100%;
    max-width: 820px; }
    .hero__right > img {
      width: 100%;
      height: auto;
      border-radius: 35px; }
    .hero__right::before, .hero__right::after {
      position: absolute;
      z-index: 1; }
    .hero__right::before {
      content: url("../images/hero-dot-2.png");
      top: -85px;
      right: -78px; }
    .hero__right::after {
      content: url("../images/hero-dot-1.png");
      top: 55%;
      left: -78px; }
  .hero__date {
    font-size: 14px;
    line-height: 1.5;
    color: #fff;
    margin: 0 0 23px;
    font-weight: 700; }
  .hero__title {
    font-size: 50px;
    line-height: 64px;
    font-weight: 700;
    color: #EEC57C;
    margin: 0 0 49px; }
  .hero__text {
    color: #fff;
    font-weight: 20px;
    line-height: 1.5;
    margin: 0 0 49px;
    max-width: 80%; }
  .hero__cta .btn {
    font-weight: 700;
    text-transform: uppercase;
    transition: all .3s ease; }
  .hero__cta .cta__primary {
    border: 1px solid #EEC57C;
    font-size: 12px;
    line-height: 1.5;
    color: #000;
    border-radius: 150px;
    padding: 26px 50px;
    background-color: #EEC57C;
    margin-right: 11px; }
    .hero__cta .cta__primary:hover {
      opacity: .8; }
  .hero__cta .cta__secondary {
    display: inline-flex;
    align-items: center;
    border: 1px solid #EEC57C;
    font-size: 12px;
    line-height: 1.5;
    color: #fff;
    border-radius: 150px;
    padding: 26px 50px;
    background-color: #000; }
    .hero__cta .cta__secondary::after {
      content: url("../images/btn-icon.png");
      margin-left: 8px;
      transform: translateY(2px); }
    .hero__cta .cta__secondary:hover {
      color: #EEC57C; }
  @media (max-width: 1699px) {
    .hero::after {
      display: none; }
    .hero__left {
      padding-bottom: 50px; }
    .hero__right {
      max-width: 500px; } }
  @media (max-width: 1199px) {
    .hero__container {
      flex-direction: column; }
    .hero__left {
      margin-top: 0;
      max-width: 90%; }
    .hero__right {
      align-self: center;
      padding-bottom: 50px; } }
  @media (max-width: 767px) {
    .hero__title {
      font-size: 40px;
      line-height: 50px;
      margin-bottom: 30px; }
    .hero__text {
      max-width: 100%; }
    .hero__cta .cta__primary, .hero__cta .cta__secondary {
      padding: 15px 20px; }
    .hero__nav .swiper-button-prev,
    .hero__nav .swiper-button-next {
      padding: 20px; }
      .hero__nav .swiper-button-prev::after,
      .hero__nav .swiper-button-next::after {
        display: none; }
    .hero__nav .swiper-button-next {
      margin-left: 20px; } }
  @media (max-width: 575px) {
    .hero__left {
      max-width: 100%; } }
  @media (max-width: 500px) {
    .hero__inner {
      padding-top: 60px; }
    .hero__title {
      font-size: 35px;
      line-height: 1.2; }
    .hero__text {
      margin-bottom: 15px; }
    .hero__cta {
      display: flex;
      flex-direction: column; }
      .hero__cta .cta__primary, .hero__cta .cta__secondary {
        text-align: center;
        display: flex;
        justify-content: center;
        margin-right: 0; }
      .hero__cta .cta__primary {
        margin-bottom: 15px; } }

.services__inner {
  position: relative;
  margin-top: 172px; }

.services__bg {
  position: absolute;
  top: 40%;
  right: 0;
  background-color: #EEC57C;
  width: 60%;
  border-top-left-radius: 114px;
  border-bottom-left-radius: 114px;
  min-height: 980px;
  z-index: -2;
  display: flex;
  align-items: flex-end;
  padding-bottom: 33px;
  padding-left: 190px; }
  .services__bg-text {
    font-size: 173px;
    font-weight: 700;
    line-height: 206px;
    color: #FFD180;
    white-space: nowrap; }

.services__content {
  max-width: 950px;
  margin: 0 auto 100px; }

.services__title {
  font-size: 41px;
  line-height: 1.5;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 38px;
  justify-content: flex-start; }
  .services__title::before {
    content: url("../images/quote.png");
    margin-bottom: 38px; }

.services__text {
  font-size: 20px;
  line-height: 1.5; }
  .services__text > p {
    margin-bottom: 15px; }

.services__row {
  display: flex;
  align-items: flex-end; }
  .services__row:first-child .services__item:last-child {
    position: relative; }
    .services__row:first-child .services__item:last-child::after {
      position: absolute;
      content: url("../images/services-dot.png");
      top: -85px;
      right: -78px;
      z-index: -1; }
  .services__row:last-child .services__item:first-child {
    position: relative; }
    .services__row:last-child .services__item:first-child::after {
      position: absolute;
      content: url("../images/hero-dot-2.png");
      bottom: -85px;
      left: -78px;
      z-index: -1; }

.services__item {
  background-color: #F5F5F5;
  border-radius: 34px;
  display: flex;
  align-items: flex-end;
  height: 324px;
  padding: 32px;
  margin-bottom: 16px;
  width: 24%;
  margin-right: 12px; }
  .services__item-title {
    font-size: 18px;
    line-break: 1.5;
    color: #000;
    max-width: 212px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; }
    .services__item-title::after {
      content: url("../images/services-arrow.png");
      margin-top: 33px;
      transition: all .4s ease; }
  .services__item:hover .services__item-title::after {
    transform: translateX(10px); }

.services .see__more {
  display: none; }

@media (max-width: 1359px) {
  .services__inner {
    margin-top: 100px; } }

@media (max-width: 1199px) {
  .services__item {
    height: auto;
    min-height: 270px;
    padding: 20px; }
    .services__item-title {
      font-size: 16px;
      line-height: 1.4; }
  .services__bg {
    width: 50%;
    min-height: 500px;
    top: 60%;
    padding-left: 30px; }
    .services__bg-text {
      font-size: 83px;
      line-height: 1.2; } }

@media (max-width: 991px) {
  .services__inner {
    margin-top: 60px; }
  .services__title {
    font-size: 35px;
    line-height: 1.3; }
    .services__title::before {
      margin-bottom: 0; }
  .services__content {
    margin-bottom: 60px; }
  .services__text {
    font-size: 17px; }
  .services__row:first-child .services__item:last-child::after {
    display: none; }
  .services__item {
    height: auto;
    min-height: 230px;
    padding: 15px; }
    .services__item-title {
      font-size: 15px; }
      .services__item-title::after {
        margin-top: 15px; }
  .services__bg {
    top: 55%;
    width: 60%;
    padding-left: 100px; } }

@media (max-width: 767px) {
  .services__row {
    flex-wrap: wrap;
    justify-content: center; }
    .services__row:not(:first-child) {
      display: none; }
  .services__bg {
    display: none; }
  .services__item {
    width: 100%;
    padding: 30px; }
    .services__item-title {
      font-size: 20px;
      max-width: 80%; }
  .services .see__more {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px; }
    .services .see__more .btn {
      border: 1px solid #EEC57C;
      font-size: 12px;
      line-height: 1.5;
      color: #000;
      border-radius: 150px;
      padding: 26px 50px;
      background-color: #EEC57C;
      padding: 15px 30px;
      text-transform: uppercase;
      font-weight: 700; } }

@media (max-width: 575px) {
  .services__title {
    font-size: 30px; }
  .services__item {
    padding: 20px;
    min-height: 170px; }
    .services__item-title {
      font-size: 17px;
      max-width: 80%; } }

.collaboration__inner {
  margin-top: 227px; }

.collaboration__container {
  position: relative;
  display: flex;
  align-items: center; }
  .collaboration__container::after {
    content: '';
    position: absolute;
    top: 15%;
    left: 0;
    width: 100%;
    height: 70%;
    background-color: #F5F5F5;
    border-radius: 34px;
    z-index: -1; }
  .collaboration__container::before {
    position: absolute;
    content: url("../images/services-dot.png");
    top: calc(-85px + 15%);
    right: -78px;
    z-index: -1; }

.collaboration__left {
  position: relative;
  padding-left: 18%;
  width: 100%;
  max-width: 828px;
  margin-right: 92px; }
  .collaboration__left::before {
    position: absolute;
    content: url("../images/hero-dot-2.png");
    bottom: calc(-85px - 30%);
    left: -78px;
    z-index: -1; }

.collaboration__right {
  width: 100%;
  max-width: 409px; }
  .collaboration__right > img {
    width: 100%;
    height: auto; }

.collaboration__title {
  font-size: 41px;
  line-height: 1.5;
  margin: 0 0 45px;
  width: 100%; }

.collaboration__cta {
  display: flex; }
  .collaboration__cta .cta__primary {
    border: 1px solid #EEC57C;
    font-size: 12px;
    line-height: 1.5;
    color: #000;
    border-radius: 150px;
    padding: 26px 50px;
    background-color: transparent;
    margin-right: 16px; }
    .collaboration__cta .cta__primary:hover {
      color: #EEC57C; }
  .collaboration__cta .cta__secondary {
    border: 1px solid #EEC57C;
    font-size: 12px;
    line-height: 1.5;
    color: #000;
    border-radius: 150px;
    padding: 26px 50px;
    background-color: #EEC57C; }
    .collaboration__cta .cta__secondary:hover {
      background-color: transparent;
      color: #EEC57C; }
  .collaboration__cta .btn {
    display: inline-block;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    padding-left: 0;
    padding-right: 0;
    min-width: 250px;
    transition: all .3s ease; }

@media (max-width: 1699px) {
  .collaboration__left {
    padding-left: 10%;
    max-width: 700px; } }

@media (max-width: 1359px) {
  .collaboration__left {
    padding-left: 5%;
    max-width: 600px; } }

@media (max-width: 1199px) {
  .collaboration__inner {
    margin-top: 100px; }
  .collaboration__title {
    font-size: 35px;
    line-height: 1.3; }
  .collaboration__cta .btn {
    min-width: 200px;
    padding: 20px 10px; } }

@media (max-width: 991px) {
  .collaboration__left {
    max-width: 400px; }
  .collaboration__title {
    font-size: 25px;
    margin-bottom: 20px; }
  .collaboration__cta .btn {
    padding: 15px 10px;
    min-width: 180px; } }

@media (max-width: 767px) {
  .collaboration__inner {
    background-color: #f5f5f5; }
  .collaboration__container {
    flex-direction: column;
    padding: 30px 15px; }
    .collaboration__container::after {
      display: none; }
  .collaboration__left {
    margin-bottom: 30px;
    margin-right: 0;
    padding-left: 0;
    max-width: unset;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .collaboration__title {
    text-align: center;
    width: 100%; } }

@media (max-width: 575px) {
  .collaboration__container {
    padding-top: 50px; }
  .collaboration__cta {
    display: flex;
    flex-direction: column;
    width: 100%; }
    .collaboration__cta .btn {
      margin-right: 0;
      width: 100%; }
      .collaboration__cta .btn:nth-child(1) {
        margin-bottom: 15px; } }

.work__inner {
  margin-top: 82px; }

.work__container {
  position: relative; }
  .work__container::after {
    content: "";
    position: absolute;
    top: 0;
    left: -100vw;
    width: 100vw;
    height: 100%;
    background-color: #fff;
    z-index: 2; }

.work__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 80px; }

.work__title {
  font-size: 41px;
  line-height: 1.5;
  font-weight: 700;
  margin: 0; }

.work__nav {
  position: relative;
  display: flex;
  flex-direction: row-reverse; }
  .work__nav > div {
    position: static;
    padding: 30px;
    border: 2px solid #EEC57C;
    border-radius: 50%; }
    .work__nav > div > img {
      max-width: 17px; }
    .work__nav > div::after {
      display: none; }
  .work__nav .swiper-button-next {
    margin-left: 45px; }
    .work__nav .swiper-button-next img {
      transform: rotate(180deg); }

.work .swiper-slide {
  position: relative; }
  .work .swiper-slide::before {
    content: '';
    position: absolute;
    top: -39.5px;
    left: -5.5px;
    width: 30px;
    height: 19px;
    background-color: #fff; }
  .work .swiper-slide::after {
    content: '';
    position: absolute;
    top: -39.5px;
    left: 0;
    width: 19px;
    height: 19px;
    background-color: #DBDBDB;
    border-radius: 50%; }
  .work .swiper-slide.swiper-slide-active::before {
    left: 0;
    width: 40px; }
  .work .swiper-slide.swiper-slide-active::after {
    top: -46.5px;
    width: 33px;
    height: 33px;
    background-color: #EEC57C; }

.work .slide__line {
  width: 100%;
  position: relative;
  margin-bottom: 30px;
  height: 1px; }
  .work .slide__line::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100vw;
    background-color: #DBDBDB; }

.work .slide__subtitle {
  font-size: 14px;
  line-height: 1.5;
  color: #EEC57C;
  margin: 0 0 19px;
  font-weight: 700; }

.work .slide__title {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 700;
  margin-bottom: 12px; }

.work .slide__text {
  font-size: 14px;
  line-height: 1.5; }

@media (max-width: 991px) {
  .work__title {
    font-size: 35px; }
  .work__nav > div {
    padding: 20px;
    margin-top: 0; } }

@media (max-width: 767px) {
  .work__top {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 50px; }
  .work__title {
    font-size: 30px;
    margin-bottom: 15px; }
  .work__nav .swiper-button-next {
    margin-left: 20px; } }

.faq__inner {
  position: relative;
  padding-top: 153px;
  z-index: 2; }
  .faq__inner::after {
    content: 'JAK POMAGAM?';
    position: absolute;
    top: -70px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 200px;
    line-height: 1.5;
    font-weight: 700;
    color: #F5F5F5;
    white-space: nowrap; }

.faq__container-inner {
  background-color: #1A1A1A;
  border-radius: 55px;
  padding: 85px 15px 37px; }

.faq__title-main {
  font-size: 41px;
  line-height: 1.5;
  color: #fff;
  max-width: 605px;
  text-align: center;
  margin: 0 auto 120px; }

.faq__list {
  max-width: 1086px;
  margin: 0 auto; }

.faq__item {
  margin-bottom: 73px; }

.faq__top {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.faq__title {
  font-size: 20px;
  line-height: 1.5;
  color: #fff;
  font-weight: 700;
  padding-right: 20px; }

.faq__trigger {
  font-size: 12px;
  line-height: 1.5;
  color: #fff;
  font-weight: 700;
  text-decoration: underline; }

.faq__content {
  font-size: 14px;
  line-height: 1.5;
  color: #fff;
  padding-right: 20px;
  max-width: 85%;
  padding-top: 30px;
  max-height: 0;
  transition: all .3s ease;
  overflow: hidden; }

@media (max-width: 1699px) {
  .faq__inner::after {
    font-size: 130px;
    top: 0;
    line-height: 1.6; } }

@media (max-width: 1199px) {
  .faq__inner::after {
    font-size: 100px;
    top: 0;
    line-height: 2.3; }
  .faq__container-inner {
    padding-left: 30px;
    padding-right: 30px; }
  .faq__title-main {
    margin-bottom: 60px; }
  .faq__item {
    margin-bottom: 30px; } }

@media (max-width: 991px) {
  .faq__inner {
    padding-top: 100px; }
    .faq__inner::after {
      font-size: 80px;
      top: 0;
      line-height: 1.7; }
  .faq__title {
    font-size: 17px; }
  .faq__item {
    margin-bottom: 20px; } }

@media (max-width: 767px) {
  .faq__inner {
    background-color: #000;
    margin-top: 60px;
    padding-top: 0; }
    .faq__inner::after {
      display: none; }
  .faq__container-inner {
    background-color: transparent;
    padding: 50px 0; } }

@media (max-width: 575px) {
  .faq__title {
    font-size: 16px; }
    .faq__title-main {
      font-size: 30px; }
  .faq__content {
    max-width: 100%;
    padding-right: 0;
    font-size: 13px; } }

.contact__inner {
  position: relative;
  margin-top: 176px;
  margin-bottom: -7px; }
  .contact__inner::after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 180%;
    background-color: #F5F5F5;
    border-radius: 34px; }

.contact__container {
  position: relative;
  display: flex;
  align-items: flex-start;
  z-index: 2;
  transform: translateY(27px); }
  .contact__container::after {
    content: 'KONTAKT';
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 200px;
    color: rgba(213, 213, 213, 0.24);
    font-weight: 700;
    line-height: .8; }

.contact__title {
  font-size: 41px;
  line-height: 1.5;
  font-weight: 700;
  max-width: 812px;
  width: 100%;
  margin-bottom: 36px; }

.contact__text {
  font-size: 20px;
  line-height: 1.5;
  max-width: 470px;
  width: 100%;
  margin-bottom: 4px; }

.contact__items {
  display: flex;
  flex-wrap: wrap; }

.contact__item:nth-child(1) {
  margin-right: 87px; }

.contact__item:last-child {
  margin-top: 32px; }

.contact__link:hover {
  opacity: .7; }

.contact__link,
.contact .address {
  font-size: 29px;
  line-height: 1.5;
  font-weight: 700;
  color: #000; }

@media (max-width: 1359px) {
  .contact__inner {
    margin-top: 100px; } }

@media (max-width: 1199px) {
  .contact__inner {
    margin-top: 100px; }
  .contact__container {
    align-items: flex-end;
    padding-bottom: 30px; }
  .contact__title {
    font-size: 35px;
    line-height: 1.4; }
  .contact .img {
    transform: translateY(30px); }
    .contact .img img {
      width: 100%; } }

@media (max-width: 991px) {
  .contact__inner {
    margin-top: 60px; }
  .contact__title {
    font-size: 30px;
    margin-bottom: 15px; }
  .contact__text {
    font-size: 17px;
    margin-bottom: 15px; }
  .contact__item:last-child {
    margin-top: 0; }
  .contact__link,
  .contact .address {
    font-size: 20px; } }

@media (max-width: 767px) {
  .contact__container {
    flex-direction: column; }
    .contact__container::after {
      display: none; }
  .contact__item {
    margin-right: 0; }
  .contact__text {
    max-width: 100%; }
  .contact .img {
    width: 60%;
    margin: 0 auto; } }

@media (max-width: 500px) {
  .contact__inner {
    margin-top: 50px; }
  .contact__title {
    font-size: 27px;
    line-height: 1.3; }
  .contact .img {
    width: 80%; } }

.form__inner {
  padding-top: 119px;
  padding-bottom: 133px;
  border-bottom-left-radius: 34px;
  border-bottom-right-radius: 34px;
  background-color: #000; }

.form__container {
  position: relative;
  z-index: 1; }
  .form__container::after {
    content: 'FORMULARZ';
    position: absolute;
    bottom: -140px;
    left: 0;
    font-size: 200px;
    line-height: .8;
    font-weight: 700;
    color: #131313;
    z-index: -1; }

.form__title {
  font-size: 41px;
  line-height: 1.5;
  color: #fff;
  font-weight: 700;
  margin-bottom: 73px;
  max-width: 633px; }

.form__top input {
  border: none;
  border-bottom: 1px solid #fff;
  width: calc(25% - 13.5px);
  color: #fff;
  background-color: transparent;
  font-size: 16px;
  padding-bottom: 13.5px;
  outline: none; }
  .form__top input:not(:last-child) {
    margin-right: 13.5px; }
  .form__top input::placeholder {
    font-weight: 700;
    color: #fff; }

.form__bottom {
  margin-top: 36px; }
  .form__bottom > input {
    border: none;
    border-bottom: 1px solid #fff;
    width: 100%;
    color: #fff;
    background-color: transparent;
    font-size: 16px;
    padding-bottom: 13.5px;
    outline: none; }
    .form__bottom > input::placeholder {
      font-weight: 700;
      color: #fff; }
  .form__bottom .regulation {
    position: relative;
    margin-top: 36px;
    margin-bottom: 48.5px; }
    .form__bottom .regulation input {
      position: absolute;
      top: 0;
      left: 0;
      width: 32px;
      height: 32px;
      opacity: 0; }
    .form__bottom .regulation input:checked ~ label svg {
      opacity: 1; }
    .form__bottom .regulation label {
      display: flex;
      align-items: flex-start;
      justify-content: stretch;
      font-size: 13px;
      line-height: 1.5;
      color: #fff; }
      .form__bottom .regulation label .check {
        position: relative;
        margin-right: 18px;
        display: inline-block;
        width: 32px;
        height: 32px;
        border: 1px solid #fff; }
        .form__bottom .regulation label .check > svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 20px;
          height: auto;
          opacity: 0; }
      .form__bottom .regulation label > p {
        width: calc(100% - 50px); }

.form .cta__container {
  display: flex;
  align-items: center;
  justify-content: center; }
  .form .cta__container .cta {
    font-size: 14px;
    line-height: 1.5;
    color: #000;
    padding: 26px 50px;
    position: relative;
    min-width: 520px; }
    .form .cta__container .cta input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      border: none;
      color: #000;
      border-radius: 150px;
      font-weight: 700;
      text-transform: uppercase;
      background-color: #EEC57C;
      border: 1px solid #eec57c;
      transition: all .3s ease;
      cursor: pointer; }
      .form .cta__container .cta input:disabled {
        cursor: not-allowed; }
      .form .cta__container .cta input:disabled, .form .cta__container .cta input:hover {
        opacity: .7; }

@media (max-width: 991px) {
  .form__top input {
    width: calc(50% - 9px);
    margin-bottom: 20px; }
    .form__top input:nth-child(2n + 2) {
      margin-right: 0; } }

@media (max-width: 767px) {
  .form__container::after {
    display: none; }
  .form__title {
    font-size: 30px;
    margin-bottom: 50px; } }

@media (max-width: 575px) {
  .form__inner {
    padding-bottom: 60px; }
  .form__title {
    font-size: 27px; }
  .form__top input {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0; }
    .form__top input:nth-child(2n + 2) {
      margin-right: 0; }
  .form__bottom {
    margin-top: 0; }
  .form .cta__container .cta {
    min-width: unset;
    width: 100%; } }

footer .footer__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 67px;
  padding-bottom: 50px; }

footer .footer__logo {
  display: inline-block;
  max-width: 340px; }
  footer .footer__logo img {
    width: 100%;
    height: auto; }

footer .footer__list {
  display: flex;
  align-items: center; }

footer .footer__item:not(:last-child) {
  margin-right: 98px; }

footer .footer__item > a {
  font-size: 14px;
  line-height: 1.5;
  color: #000;
  text-transform: uppercase; }
  footer .footer__item > a:hover {
    color: #EEC57C; }

@media (max-width: 1699px) {
  footer .footer__logo {
    max-width: 250px; } }

@media (max-width: 1359px) {
  footer .footer__item:not(:last-child) {
    margin-right: 50px; }
  footer .footer__item > a {
    font-size: 14px;
    line-height: 1.5;
    color: #000;
    text-transform: uppercase; }
    footer .footer__item > a:hover {
      color: #EEC57C; } }

@media (max-width: 1199px) {
  footer .footer__logo {
    max-width: 200px; } }

@media (max-width: 991px) {
  footer .footer__container {
    flex-direction: column; }
  footer .footer__nav {
    margin-top: 30px;
    width: 100%; }
  footer .footer__list {
    justify-content: space-between; }
  footer .footer__logo {
    align-self: flex-start;
    max-width: 170px; } }

@media (max-width: 767px) {
  footer .footer__container {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 40px; }
  footer .footer__nav {
    margin-top: 0;
    width: auto; }
  footer .footer__list {
    flex-direction: column;
    align-items: flex-start; } }

@media (max-width: 440px) {
  footer .footer__container {
    flex-direction: column;
    align-items: flex-start; }
  footer .footer__logo {
    max-width: 200px; }
  footer .footer__nav {
    margin-top: 30px; } }

//  HEADER
.header {
  &__inner {
    background-color: #000;
  }
  &__container {
    padding-top: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__logo {
    display: inline-block;
    max-width: 367px;
    > img {
      width: 100%;
      height: auto;
    }
  }
  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__item {
    &:not(:last-child) {
      margin-right: 89px;
    }
    > a {
      font-size: 14px;
      line-height: 1.5;
      color: #fff;
      font-weight: 700;
      text-transform: uppercase;
      &:hover {
        color: $mainColor
      }
    }
    &.btn {
      @include btn(14px, $mainColor, #000);
      padding: 0;
      transition: all .2s ease;
      > a {
        color: #000;
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: 26px 50px;

      }
      &:hover {
        opacity: .8;
      }
    }
  }
  &__burger {
    display: none;
  }
  @media (max-width: 1699px) {
    &__logo {
      max-width: 300px;
    }
    &__item:not(:last-child) {
      margin-right: 50px;
    }
  }
  @media (max-width: 1359px) {
    &__logo {
      max-width: 270px;
    }
    &__item {
      &.btn {
        >a {
          padding: 15px 40px;
        }
      }
    }
  }
  @media (max-width: 1199px) {
    &__burger {
      display: flex;
    }
    &__nav {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: #000;
      z-index: 99;
      opacity: 0;
      visibility: hidden;
      transition: all .5s ease;
      &.active {
        opacity: 1;
        visibility: visible;
      }
    }
    &__list {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    &__item {
      margin-bottom: 20px;
      &:not(:last-child) {
        margin-right: 0;
      }
      > a {
        font-size: 20px;
      }
      &.btn {
        border: none;
        color: #fff;
        border-radius: 0;
        background-color:transparent;
        > a {
          padding: 0;
          color: #fff;
          &:hover {
            color: $mainColor;
          }
        }
      }
    }
  }
  @media (max-width: 575px) {
    &__container {
      padding-top: 30px;
    }
    &__logo {
      max-width: 180px;
    }
  }
}

// HERO 
.hero {
  background-color: #000;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 159px;
    background-color: #fff;
  }
  &__inner {
    padding-top: 100px;
    margin-top: -1px;
    position: relative;
    z-index: 2;
  }
  &__container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__left {
    width: 100%;
    max-width: 675px;
    margin-top: 100px;
    overflow: hidden;
  }
  &__nav {
    margin-top: 49px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    .swiper-button-prev, 
    .swiper-button-next {
      position: static;
      border: 2px solid $mainColor;
      padding: 30px;
      border-radius: 50%;
      &::after {
        display: none;
      }
    }
    .swiper-button-next {
      margin-left: 45px;
      img {
        transform: rotate(180deg);
      }
    }
  }
  &__right {
    position: relative;
    width: 100%;
    max-width: 820px;
    > img {
      width: 100%;
      height: auto;
      border-radius: 35px;
    }
    &::before,
    &::after {
      position: absolute;
      z-index: 1;
    }
    &::before {
      content: url('../images/hero-dot-2.png');
      top: -85px;
      right: -78px;
    }
    &::after {
      content: url('../images/hero-dot-1.png');
      top: 55%;
      left: -78px;
    }
  }
  &__date {
    font-size: 14px;
    line-height: 1.5;
    color: #fff;
    margin: 0 0 23px;
    font-weight: 700;
  }
  &__title {
    font-size: 50px;
    line-height: 64px;
    font-weight: 700;
    color: $mainColor;
    margin: 0 0 49px;
  }
  &__text {
    color: #fff;
    font-weight: 20px;
    line-height: 1.5;
    margin: 0 0 49px;
    max-width: 80%;
  }
  &__cta {
    .btn {
      font-weight: 700;
      text-transform: uppercase;
      transition: all .3s ease;
    }
    .cta {
      &__primary {
        @include btn(12px, $mainColor, #000);
        margin-right: 11px;
        &:hover {
          opacity: .8;
        }
      }
      &__secondary {
        display: inline-flex;
        align-items: center;
        @include btn(12px, #000, #fff);
        &::after {
          content: url('../images/btn-icon.png');
          margin-left: 8px;
          transform: translateY(2px);
        }
        &:hover {
          color: $mainColor;
        }
      }
    }
  }
  @media (max-width: 1699px) {
    &::after {
      display: none;
    }
    &__left {
      padding-bottom: 50px;
    }
    &__right {
      max-width: 500px;
    }
  }
  @media (max-width: 1199px) {
    &__container {
      flex-direction: column;
    }
    &__left {
      margin-top: 0;
      max-width: 90%;
    }
    &__right {
      align-self: center;
      padding-bottom: 50px;
    }
  }
  @media (max-width: 767px) {
    &__title {
      font-size: 40px;
      line-height: 50px;
      margin-bottom: 30px;
    }
    &__text {
      max-width: 100%;
    }
    &__cta {
      .cta {
        &__primary,
        &__secondary {
          padding: 15px 20px;
        }
      }
    }
    &__nav {
      .swiper-button-prev, 
      .swiper-button-next {
        padding: 20px;
        &::after {
          display: none;
        }
      }
      .swiper-button-next {
        margin-left: 20px;
      }
    }
  }
  @media (max-width: 575px) {
    &__left {
      max-width: 100%;
    }
  }
  @media (max-width: 500px) {
    &__inner {
      padding-top: 60px;
    }
    &__title {
      font-size: 35px;
      line-height: 1.2;
    }
    &__text {
      margin-bottom: 15px;
    }
    &__cta {
      display: flex;
      flex-direction: column;
      .cta {
        &__primary,
        &__secondary {
          text-align: center;
          display: flex;
          justify-content: center;
          margin-right: 0;
        }
        &__primary {
          margin-bottom: 15px;
        }
      }
    }
  }
}

// SERVICES
.services {
  &__inner {
    position: relative;
    margin-top: 172px;
  }
  &__bg {
    position: absolute;
    top: 40%;
    right: 0;
    background-color: $mainColor;
    width: 60%;
    border-top-left-radius: 114px;
    border-bottom-left-radius: 114px;
    min-height: 980px;
    z-index: -2;
    display: flex;
    align-items: flex-end;
    padding-bottom: 33px;
    padding-left: 190px;
    &-text {
      font-size: 173px;
      font-weight: 700;
      line-height: 206px;
      color: #FFD180;
      white-space: nowrap;
    }
  }
  &__content {
    max-width: 950px;
    margin: 0 auto 100px;
  }
  &__title {
    font-size: 41px;
    line-height: 1.5;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 38px;
    justify-content: flex-start;
    &::before {
      content: url('../images/quote.png');
      margin-bottom: 38px;
    }
  }
  &__text {
    font-size: 20px;
    line-height: 1.5;
    > p {
      margin-bottom: 15px;
    }
  }
  &__row {
    display: flex;
    align-items: flex-end;
    &:first-child {
      .services__item {
        &:last-child {
          position: relative;
          &::after {
            position: absolute;
            content: url('../images/services-dot.png');
            top: -85px;
            right: -78px;
            z-index: -1;
          }
        }
      }
    }
    &:last-child {
      .services__item {
        &:first-child {
          position: relative;
          &::after {
            position: absolute;
            content: url('../images/hero-dot-2.png');
            bottom: -85px;
            left: -78px;
            z-index: -1;
          }
        }
      }
    }
  }
  &__item {
    background-color: #F5F5F5;
    border-radius: 34px;
    display: flex;
    align-items: flex-end;
    height: 324px;
    padding: 32px;
    margin-bottom: 16px;
    width: 24%;
    margin-right: 12px;
    &-title {
      font-size: 18px;
      line-break: 1.5;
      color: #000;
      max-width: 212px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      &::after {
        content: url('../images/services-arrow.png');
        margin-top: 33px;
        transition: all .4s ease;
      }
    }
    &:hover {
      .services__item-title {
        &::after {
          transform: translateX(10px);
        }
      }
    }
  }
  .see__more {
    display: none;
  }
  @media (max-width: 1359px) {
    &__inner {
      margin-top: 100px;
    }
  }
  @media (max-width: 1199px) {
    &__item {
      height: auto;
      min-height: 270px;
      padding: 20px;
      &-title {
        font-size: 16px;
        line-height: 1.4;
      }
    }
    &__bg {
      width: 50%;
      min-height: 500px;
      top: 60%;
      padding-left: 30px;
      &-text {
        font-size: 83px;
        line-height: 1.2;
      }
    }
  }
  @media (max-width: 991px) {
    &__inner {
      margin-top: 60px;
    }
    &__title {
      font-size: 35px;
      line-height: 1.3;
      &::before{
        margin-bottom: 0;
      }
    }
    &__content {
      margin-bottom: 60px;
    }
    &__text {
      font-size: 17px;
    }
    &__row {
      &:first-child {
        .services__item {
          &:last-child {
            &::after {
              display: none;
            }
          }
        }
      }
    }
    &__item {
      height: auto;
      min-height: 230px;
      padding: 15px;
      &-title {
        font-size: 15px;
        &::after {
          margin-top: 15px;
        }
      }
    }
    &__bg {
      top: 55%;
      width: 60%;
      padding-left: 100px;
    }
  }
  @media (max-width: 767px) {
    &__row {
      flex-wrap: wrap;
      justify-content: center;
      &:not(:first-child) {
        display: none;
      }
    }
    &__bg {
      display: none;
    }
    &__item {
      width: 100%;
      padding: 30px;
      &-title {
        font-size: 20px;
        max-width: 80%;
      }
    }
    .see__more {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      .btn {
        @include btn(12px, $mainColor, #000);
        padding: 15px 30px;
        text-transform: uppercase;
        font-weight: 700;
      }
    }
  }
  @media (max-width: 575px) {
    &__title {
      font-size: 30px;
    }
    &__item {
      padding: 20px;
      min-height: 170px;
      &-title {
        font-size: 17px;
        max-width: 80%;
      }
    }
  }
}

// COLLABORATION
.collaboration {
  &__inner {
    margin-top: 227px;
  }
  &__container {
    position: relative;
    display: flex;
    align-items: center;
    &::after {
      content: '';
      position: absolute;
      top: 15%;
      left: 0;
      width: 100%;
      height: 70%;
      background-color: #F5F5F5;
      border-radius: 34px;
      z-index: -1;
    }
    &::before {
      position: absolute;
      content: url('../images/services-dot.png');
      top: calc(-85px + 15%);
      right: -78px;
      z-index: -1;
    }
  }
  &__left {
    position: relative;
    padding-left: 18%;
    width: 100%;
    max-width: 828px;
    margin-right: 92px;
    &::before {
      position: absolute;
      content: url('../images/hero-dot-2.png');
      bottom: calc(-85px - 30%);
      left: -78px;
      z-index: -1;
    }
  }
  &__right {
    width: 100%;
    max-width: 409px;
    > img {
      width: 100%;
      height: auto;
    }
  }
  &__title {
    font-size: 41px;
    line-height: 1.5;
    margin: 0 0 45px;
    width: 100%;
  }
  &__cta {
    display: flex;
    .cta {
      
      &__primary {
        @include btn(12px, transparent, #000);
        margin-right: 16px;
        &:hover {
          color: $mainColor;
        }
      }
      &__secondary {
        @include btn(12px, $mainColor, #000);
        &:hover {
          background-color: transparent;
          color: $mainColor;
        }
      }
    }
    .btn {
      display: inline-block;
      text-align: center;
      font-weight: 700;
      text-transform: uppercase;
      padding-left: 0;
      padding-right: 0;
      min-width: 250px;
      transition: all .3s ease;
    }
  }

  @media (max-width: 1699px) {
    &__left {
      padding-left: 10%;
      max-width: 700px;
    } 
  }
  @media (max-width: 1359px) {
    &__left {
      padding-left: 5%;
      max-width: 600px;
    } 
  }
  @media (max-width: 1199px) {
    &__inner {
      margin-top: 100px;
    }
    &__title {
      font-size: 35px;
      line-height: 1.3;
    }
    &__cta {
      .btn {
        min-width: 200px;
        padding: 20px 10px;
      }
    }

  }
  @media (max-width: 991px) {
    &__left {
      max-width: 400px;
    }
    &__title {
      font-size: 25px;
      margin-bottom: 20px;
    }
    &__cta {
      .btn {
        padding: 15px 10px;
        min-width: 180px
      }
    }
  }
  @media (max-width: 767px) {
    &__inner {
      background-color: #f5f5f5;
    }
    &__container {
      flex-direction: column;
      padding: 30px 15px;
      &::after {
        display: none;
      }
    }
    &__left {
      margin-bottom: 30px;
      margin-right: 0;
      padding-left: 0;
      max-width: unset;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__title {
      text-align: center;
      width: 100%;
    }
  }
  @media (max-width: 575px) {
    &__container {
      padding-top: 50px;
    }
    &__cta {
      display: flex;
      flex-direction: column;
      width: 100%;
      .btn {
        margin-right: 0;
        width: 100%;
        &:nth-child(1) {
          margin-bottom: 15px;
        }
      }
    }
  }
}

// WORK
.work {
  &__inner {
    margin-top: 82px;
  }
  &__container {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: -100vw;
      width: 100vw;
      height: 100%;
      background-color: #fff;
      z-index: 2;
    }
  }
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 80px;
  }
  &__title {
    font-size: 41px;
    line-height: 1.5;
    font-weight: 700;
    margin: 0;
  }
  &__nav {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    > div {
      position: static;
      padding: 30px;
      border: 2px solid $mainColor;
      border-radius: 50%;
      > img {
        max-width: 17px;
      }
      &::after {
        display: none;
      }
    }
    .swiper-button-next {
      margin-left: 45px;
      img {
        transform: rotate(180deg);
      }
    }
  }
  .swiper-slide {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: -39.5px;
      left: -5.5px;
      width: 30px;
      height: 19px;
      background-color: #fff;
    }
    &::after {
      content: '';
      position: absolute;
      top: -39.5px;
      left: 0;
      width: 19px;
      height: 19px;
      background-color: #DBDBDB;
      border-radius: 50%;
    }
    &.swiper-slide-active {
      &::before {
        left: 0;
        width: 40px;
      }
      &::after {
        top: -46.5px;
        width: 33px;
        height: 33px;
        background-color: $mainColor;
      }
    }
  }
  .slide {
    &__line {
      width: 100%;
      position: relative;
      margin-bottom: 30px;
      height: 1px;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100vw;
        background-color: #DBDBDB;
      }
    }
    &__subtitle {
      font-size: 14px;
      line-height: 1.5;
      color: $mainColor;
      margin: 0 0 19px;
      font-weight: 700;
    }
    &__title {
      font-size: 18px;
      line-height: 1.5;
      font-weight: 700;
      margin-bottom: 12px;
    }
    &__text {
      font-size: 14px;
      line-height: 1.5;
    }
  }
  @media (max-width: 991px) {
    &__title {
      font-size: 35px;
    }
    &__nav {
      > div {
        padding: 20px;
        margin-top: 0;
      }
    }
  }
  @media (max-width: 767px) {
    &__top {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 50px;
    }
    &__title {
      font-size: 30px;
      margin-bottom: 15px;
    }
    &__nav {
      .swiper-button-next {
        margin-left: 20px;
      }
    }
  }

}


// FAQ
.faq {
  &__inner {
    position: relative;
    padding-top: 153px;
    z-index: 2;
    &::after {
      content: 'JAK POMAGAM?';
      position: absolute;
      top: -70px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 200px;
      line-height: 1.5;
      font-weight: 700;
      color: #F5F5F5;
      white-space: nowrap;
    }
  }
  &__container {
    &-inner {
      background-color: #1A1A1A;
      border-radius: 55px;
      padding: 85px 15px 37px;
    }
  }
  &__title-main {
    font-size: 41px;
    line-height: 1.5;
    color: #fff;
    max-width: 605px;
    text-align: center;
    margin: 0 auto 120px;
  }
  &__list {
    max-width: 1086px;
    margin: 0 auto;
  }
  &__item {
    margin-bottom: 73px;
  }
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__title {
    font-size: 20px;
    line-height: 1.5;
    color: #fff;
    font-weight: 700;
    padding-right: 20px;
  }
  &__trigger {
    font-size: 12px;
    line-height: 1.5;
    color: #fff;
    font-weight: 700;
    text-decoration: underline;
  }
  &__content {
    font-size: 14px;
    line-height: 1.5;
    color: #fff;
    padding-right: 20px;
    max-width: 85%;
    padding-top: 30px;
    max-height: 0;
    transition: all .3s ease;
    overflow: hidden;
  }
  @media (max-width: 1699px) {
    &__inner {
      &::after {
        font-size: 130px;
        top: 0;
        line-height: 1.6;
      }
    }
  }
  @media (max-width: 1199px) {
    &__inner {
      &::after {
        font-size: 100px;
        top: 0;
        line-height: 2.3;
      }
    }
    &__container {
      &-inner {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
    &__title {
      &-main {
        margin-bottom: 60px;
      }
    }
    &__item {
      margin-bottom: 30px;
    }
  }
  @media (max-width: 991px) {
    &__inner {
      padding-top: 100px;
      &::after {
        font-size: 80px;
        top: 0;
        line-height: 1.7;
      }
    }
    &__title {
      font-size: 17px;
    }
    &__item {
      margin-bottom: 20px;
    }
  }
  @media (max-width: 767px) {
    &__inner {
      background-color: #000;
      margin-top: 60px;
      padding-top: 0;
      &::after {
        display: none;
      }
    }
    &__container {
      &-inner {
        background-color: transparent;
        padding: 50px 0;
      }
    }
  }
  @media (max-width: 575px) {
    &__title {
      font-size: 16px;
      &-main {
        font-size: 30px;
      }
    }
    &__content {
      max-width: 100%;
      padding-right: 0;
      font-size: 13px;
    }
  }
}

// CONTACT
.contact {
  &__inner {
    position: relative;
    margin-top: 176px;
    margin-bottom: -7px;
    &::after {
      content: '';
      position: absolute;
      bottom: -20px;
      left: 0;
      width: 100%;
      height: 180%;
      background-color: #F5F5F5;
      border-radius: 34px;
    }

  }
  &__container {
    position: relative;
    display: flex;
    align-items: flex-start;
    z-index: 2;
    transform: translateY(27px);
    &::after {
      content: 'KONTAKT';
      position: absolute;
      bottom: 0;
      left: 0;
      font-size: 200px;
      color: rgba(213, 213, 213, .24);
      font-weight: 700;
      line-height: .8;
    }
  }
  &__title {
    font-size: 41px;
    line-height: 1.5;
    font-weight: 700;
    max-width: 812px;
    width: 100%;
    margin-bottom: 36px;
  }
  &__text {
    font-size: 20px;
    line-height: 1.5;
    max-width: 470px;
    width: 100%;
    margin-bottom: 4px;
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
  }
  &__item {
    &:nth-child(1) {
      margin-right: 87px;
    }
    &:last-child {
      margin-top: 32px;
    }
  }
  &__link {
    &:hover {
      opacity: .7;
    }
  }
  &__link,
  .address {
    font-size: 29px;
    line-height: 1.5;
    font-weight: 700;
    color: #000;
  }

  @media (max-width: 1359px) {
    &__inner {
      margin-top: 100px;
    }
  }
  @media (max-width: 1199px) {
    &__inner {
      margin-top: 100px;
    }
    &__container {
      align-items: flex-end;
      padding-bottom: 30px;
    }
    &__title {
      font-size: 35px;
      line-height: 1.4;
    }
    .img {transform: translateY(30px);
      img {
        width: 100%;
      }
    }
  }
  @media (max-width: 991px) {
    &__inner {
      margin-top: 60px;
    }
    &__title {
      font-size: 30px;
      margin-bottom: 15px;
    }
    &__text {
      font-size: 17px;
      margin-bottom: 15px;
    }
    &__item {
      &:last-child {
        margin-top: 0;
      }
    }
    &__link,
    .address {
      font-size: 20px;
    }
  }
  @media (max-width: 767px) {
    &__container {
      flex-direction: column;
      &::after {
        display: none;
      }
    }
    &__item {
      margin-right: 0;
    }
    &__text {
      max-width: 100%;
    }
    .img {
      width: 60%;
      margin: 0 auto;
    }
  }
  @media (max-width: 500px) {
    &__inner {
      margin-top: 50px;
    }
    &__title {
      font-size: 27px;
      line-height: 1.3;
    }
    .img {
      width: 80%;
    }
  }

}


// FORM
.form {
  &__inner {
    padding-top: 119px;
    padding-bottom: 133px;
    border-bottom-left-radius: 34px;
    border-bottom-right-radius: 34px;
    background-color: #000;
  }
  &__container {
    position: relative;
    z-index: 1;
    &::after {
      content: 'FORMULARZ';
      position: absolute;
      bottom: -140px;
      left: 0;
      font-size: 200px;
      line-height: .8;
      font-weight: 700;
      color: #131313;
      z-index: -1;
    }
  }
  &__title {
    font-size: 41px;
    line-height: 1.5;
    color: #fff;
    font-weight: 700;
    margin-bottom: 73px;
    max-width: 633px;
  }
  &__top {
    input {
      &:not(:last-child) {
        margin-right: 13.5px;
      }
      border: none;
      border-bottom: 1px solid #fff;
      width: calc(25% - 13.5px);
      color: #fff;
      background-color: transparent;
      font-size: 16px;
      padding-bottom: 13.5px;
      outline: none;
      &::placeholder {
        font-weight: 700;
        color: #fff;
      }
    }
  }
  &__bottom {
    margin-top: 36px;
    > input {
      border: none;
      border-bottom: 1px solid #fff;
      width: 100%;
      color: #fff;
      background-color: transparent;
      font-size: 16px;
      padding-bottom: 13.5px;
      outline: none;
      &::placeholder {
        font-weight: 700;
        color: #fff;
      }
    }
    .regulation {
      position: relative;
      margin-top: 36px;
      margin-bottom: 48.5px;
      input{
        position: absolute;
        top: 0;
        left: 0;
        width: 32px;
        height: 32px;
        opacity: 0;
      }
      input:checked ~ label svg {
        opacity: 1;
      }

      
      label {
        display: flex;
        align-items: flex-start;
        justify-content: stretch;
        .check {
          position: relative;
          margin-right: 18px;
          display: inline-block;
          width: 32px;
          height: 32px;
          border: 1px solid #fff;
          > svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 20px;
            height: auto;
            opacity: 0;
          }
        }
        > p {
          width: calc(100% - 50px);
        }
        font-size: 13px;
        line-height: 1.5;
        color: #fff;
      }
    }

  }
  .cta__container {
    display: flex;
    align-items: center;
    justify-content: center;
    .cta {
      font-size: 14px;
      line-height: 1.5;
      color: #000;
      padding: 26px 50px;
      position: relative;
      min-width: 520px;
      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        border: none;
        color: #000;
        border-radius: 150px;
        font-weight: 700;
        text-transform: uppercase;
        background-color: $mainColor;
        border: 1px solid #eec57c;
        transition: all .3s ease;
        cursor: pointer;
        &:disabled {
          cursor: not-allowed;
        }
        &:disabled,
        &:hover {
          opacity: .7;
        }
      }
    }
  }
  @media (max-width: 991px) {
    &__top {
      input {
        width: calc(50% - 9px);
        margin-bottom: 20px;
        &:nth-child(2n + 2) {
          margin-right: 0;
        }
      }
    }
  }
  @media (max-width: 767px) {
    &__container {
      &::after {
        display: none;
      }
    }
    &__title {
      font-size: 30px;
      margin-bottom: 50px;
    }
  }
  @media (max-width: 575px) {
    &__inner {
      padding-bottom: 60px;
    }
    &__title {
      font-size: 27px;
    }
    &__top {
      input {
        width: 100%;
        margin-bottom: 20px;
        margin-right: 0;
        &:nth-child(2n + 2) {
          margin-right: 0;
        }
      }
    }
    &__bottom {
      margin-top: 0;
    }
    .cta__container {
      .cta {
        min-width: unset;
        width: 100%;
      }
    }
  }
}


// FOOTER
footer {
  .footer {
    &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 67px;
      padding-bottom: 50px;
    }
    &__logo {
      display: inline-block;
      max-width: 340px;
      img {
        width: 100%;
        height: auto;
      }
    }
    &__list {
      display: flex;
      align-items: center;
    }
    &__item {
      &:not(:last-child) {
        margin-right: 98px;
      }
      > a {
        font-size: 14px;
        line-height: 1.5;
        color: #000;
        text-transform: uppercase;
        &:hover {
          color: $mainColor;
        }
      }
    }
    @media (max-width: 1699px) {
      &__logo {
        max-width: 250px;
      }
    }
    @media (max-width: 1359px) {
      &__item {
        &:not(:last-child) {
          margin-right: 50px;
        }
        > a {
          font-size: 14px;
          line-height: 1.5;
          color: #000;
          text-transform: uppercase;
          &:hover {
            color: $mainColor;
          }
        }
      }
    }
    @media (max-width: 1199px) {
      &__logo {
        max-width: 200px;
      }
    }
    @media (max-width: 991px) {
      &__container {
        flex-direction: column;
      }
      &__nav {
        margin-top: 30px;
        width: 100%;
      }
      &__list {
        justify-content: space-between;
      }
      &__logo {
        align-self: flex-start;
        max-width: 170px;
      }
    }
    @media (max-width: 767px) {
      &__container {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding-top: 50px;
        padding-bottom: 40px;
      }
      &__nav {
        margin-top: 0;
        width: auto;
      }
      &__list {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    @media (max-width: 440px) {
      &__container {
        flex-direction: column;
        align-items: flex-start;
      }
      &__logo {
        max-width: 200px;
      }
      &__nav {
        margin-top: 30px;
      }
    }
  }
}